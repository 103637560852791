<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :fullscreen="isFullScreen"
    :width="getWidth"
    :loading="isLoading"
    content-class="white fill-height"
  >
    <v-card flat>
      <v-toolbar v-if="parsedEvent" flat max-height="56px" fixed>
        <v-btn fab x-small @click="closeModal(false)">
          <v-icon> $close </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$vuetify.breakpoint.lgAndUp" class="ml-4">
          <div class="d-flex align-center justify-center flex-wrap">
            <div class="text-wrap">{{ eventPreviewTitle }}</div>
            <div v-if="isBookingType" class="ml-2">
              <event-status-chips :parsed-event="parsedEvent" />
            </div></div
        ></v-toolbar-title>
        <v-spacer />
        <div v-if="$vuetify.breakpoint.lgAndUp">
          <event-actions :parsed-event="parsedEvent" />
        </div>
      </v-toolbar>
      <v-toolbar
        v-if="parsedEvent && !$vuetify.breakpoint.lgAndUp"
        flat
        max-height="56px"
        fixed
        class="d-flex justify-center"
      >
        <v-toolbar-title class="d-flex align-center justify-center flex-wrap">
          <div class="text-wrap">{{ eventPreviewTitle }}</div>
          <div v-if="isBookingType" class="ml-2">
            <event-status-chips :parsed-event="parsedEvent" />
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <v-toolbar
        v-if="parsedEvent && !$vuetify.breakpoint.lgAndUp"
        flat
        max-height="56px"
        fixed
        class="d-flex justify-center mt-4"
      >
        <event-actions :parsed-event="parsedEvent" />
      </v-toolbar>
      <v-card-text v-if="isLoading" class="d-flex align-center justify-center">
        <v-progress-circular indeterminate color="primary" />
      </v-card-text>
      <template v-else-if="parsedEvent">
        <v-card-text class="mt-8 mx-0 px-0">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <template v-if="hasTags">
                  <event-tags :tags="parsedEvent.tags" />
                  <v-row
                    v-if="
                      hasService ||
                      parsedEvent.calendarFullDate ||
                      parsedEvent.employee ||
                      parsedEvent.location ||
                      canShowHistory
                    "
                  >
                    <v-col class="py-0"><v-divider /></v-col>
                  </v-row>
                </template>
                <template v-if="hasService">
                  <event-service-row
                    :service="parsedEvent.service"
                    :service-type="
                      parsedEvent.service && parsedEvent.service.selectedType
                        ? parsedEvent.service.selectedType
                        : null
                    "
                    :products="parsedEvent.products"
                    hide-details
                    dense
                  />
                  <v-row
                    v-if="
                      parsedEvent.calendarFullDate ||
                      parsedEvent.employee ||
                      parsedEvent.location ||
                      canShowHistory
                    "
                  >
                    <v-col class="py-0"><v-divider /></v-col>
                  </v-row>
                </template>
                <template v-if="parsedEvent.calendarFullDate">
                  <event-details :parsed-event="parsedEvent" />
                  <v-row
                    v-if="
                      parsedEvent.employee ||
                      parsedEvent.location ||
                      canShowHistory
                    "
                  >
                    <v-col class="py-0"><v-divider /></v-col>
                  </v-row>
                </template>
                <template v-if="parsedEvent.employee">
                  <event-employee :parsed-event="parsedEvent" />
                  <v-row v-if="parsedEvent.location || canShowHistory">
                    <v-col class="py-0"><v-divider /></v-col>
                  </v-row>
                </template>
                <template v-else-if="isUnavailableBookingSlotType">
                  <v-row>
                    <v-col>
                      <div class="font-weight-medium mb-2">
                        {{ $trans("employee") }}:
                      </div>
                      {{ $trans("all_employees") }}
                    </v-col>
                  </v-row>
                  <v-row v-if="parsedEvent.location || canShowHistory">
                    <v-col class="py-0"><v-divider /></v-col>
                  </v-row>
                </template>
                <template v-if="parsedEvent.location">
                  <event-location :parsed-event="parsedEvent" />
                  <v-row v-if="canShowHistory">
                    <v-col class="py-0"><v-divider /></v-col>
                  </v-row>
                </template>
                <event-history
                  v-if="canShowHistory"
                  :parsed-event="parsedEvent"
                />
                <v-row v-if="!$vuetify.breakpoint.mdAndUp">
                  <v-col class="py-0"><v-divider /></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <template v-if="isBookingType || isUnavailableBookingSlotType">
                  <template v-if="hasUser">
                    <event-user :parsed-event="parsedEvent" />
                    <v-row
                      v-if="
                        hasPayment || parsedEvent.description || hasCustomFields
                      "
                    >
                      <v-col class="py-0"><v-divider /></v-col>
                    </v-row>
                  </template>
                  <template v-if="hasPayment">
                    <event-payment :parsed-event="parsedEvent" />
                    <v-row v-if="parsedEvent.description || hasCustomFields">
                      <v-col class="py-0"><v-divider /></v-col>
                    </v-row>
                  </template>
                  <template v-if="parsedEvent.description">
                    <event-description :parsed-event="parsedEvent" />
                    <v-row v-if="hasCustomFields">
                      <v-col class="py-0"><v-divider /></v-col>
                    </v-row>
                  </template>
                  <template v-if="hasCustomFields">
                    <event-custom-fields
                      :custom-fields="parsedEvent.customFields"
                    />
                  </template>
                </template>
                <template v-else-if="isGroupBookingType">
                  <event-bookings :parsed-event="parsedEvent" />
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" text @click="closeModal(false)">
            {{ $trans("close") }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import eventDialogParser from "@/calendesk/mixins/eventDialogParser";
import EventStatusChips from "@/components/Event/EventStatusChips.vue";
import EventActions from "@/components/Event/EventActions.vue";
import EventTags from "@/components/Event/EventTags.vue";
import EventDetails from "@/components/Event/EventDetails.vue";
import EventLocation from "@/components/Event/EventLocation.vue";
import EventServiceRow from "@/components/Event/EventServiceRow.vue";
import EventUser from "@/components/Event/EventUser.vue";
import EventEmployee from "@/components/Event/EventEmployee.vue";
import EventPayment from "@/components/Event/EventPayment.vue";
import EventDescription from "@/components/Event/EventDescription.vue";
import EventHistory from "@/components/Event/EventHistory.vue";
import EventCustomFields from "@/components/Event/EventCustomFields.vue";
import EventBookings from "@/components/Event/EventBookings.vue";
import sharedEventDialog from "@/calendesk/mixins/sharedEventDialog";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  name: "EventPreviewDialog",
  components: {
    EventBookings,
    EventCustomFields,
    EventHistory,
    EventDescription,
    EventPayment,
    EventEmployee,
    EventUser,
    EventServiceRow,
    EventLocation,
    EventDetails,
    EventTags,
    EventActions,
    EventStatusChips,
  },
  mixins: [eventDialogParser, sharedEventDialog, roleActions],
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    hasTags() {
      return (
        this.isBookingType &&
        this.hasProperty("tags") &&
        this.parsedEvent.tags.length > 0
      );
    },
    hasService() {
      return this.isBookingOrGroupType && this.hasProperty("service");
    },
    hasUser() {
      return this.isBookingType && this.hasProperty("user");
    },
    hasPayment() {
      return (
        this.hasProperty("paymentTransaction") ||
        (this.hasProperty("simpleStoreProductPaymentTransactions") &&
          this.parsedEvent.simpleStoreProductPaymentTransactions.length > 0)
      );
    },
    canShowHistory() {
      return (
        this.loggedUserCanSeeAllEmployees &&
        (this.hasProperty("createdBy") || this.hasProperty("updatedBy"))
      );
    },
    hasCustomFields() {
      return (
        this.hasProperty("customFields") &&
        this.parsedEvent.customFields.length > 0
      );
    },
    eventPreviewTitle() {
      let result = null;
      if (this.parsedEvent) {
        result = this.$trans("event_preview_type_" + this.parsedEvent.type);

        const isGroupRecurring =
          this.parsedEvent.type === this.$helpers.eventTypes.groupBooking &&
          this.parsedEvent.bookings &&
          this.parsedEvent.bookings.length > 0 &&
          this.parsedEvent.bookings[0].groupId;

        if (this.parsedEvent.groupId || isGroupRecurring) {
          result =
            result + " - " + this.$trans("recurrence_event").toLowerCase();
        }
      }

      if (this.isBookingType || this.isUnavailableBookingSlotType) {
        result =
          result + " (" + this.$trans("id") + ": " + this.parsedEvent.id + ")";
      }

      return result;
    },
  },
  methods: {
    hasProperty(prop) {
      return this.parsedEvent && this.parsedEvent[prop];
    },
    closeModal(reload) {
      this.dialog = false;
      this.parsedEvent = null;
      this.$root.$emit("closeEventPreviewModal", reload);
    },
  },
};
</script>
