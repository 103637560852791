import { render, staticRenderFns } from "./PlanConfiguration.vue?vue&type=template&id=c48786fc&scoped=true"
import script from "./PlanConfiguration.vue?vue&type=script&lang=js"
export * from "./PlanConfiguration.vue?vue&type=script&lang=js"
import style0 from "./PlanConfiguration.vue?vue&type=style&index=0&id=c48786fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c48786fc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VDivider,VRow})
