<template>
  <div>
    <v-container
      ref="mainContainer"
      fluid
      :class="{
        'mb-12': !isInStore,
      }"
    >
      <template v-if="!isInStore">
        <v-row justify="center" no-gutters class="mb-2">
          <v-col cols="12" md="10" class="text-center">
            <div class="c-text-36 font-weight-bold mt-8 mt-md-4">
              {{ $trans("plan_selected_title", { plan: $trans(planSlug) }) }}
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-col cols="12" md="10" class="text-center">
            <span class="text-body-1 dark--text">{{
              $trans("plan_selected_subtitle_1")
            }}</span
            ><br />
            <span class="text-body-1 dark--text">{{
              $trans("plan_selected_subtitle_2")
            }}</span>
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-container
          v-if="currentPeriodPlanPackages"
          class="form__container pt-6 pb-6 mb-12"
          fluid
        >
          <v-row>
            <v-col
              v-for="plugin in currentPeriodPlanPackages"
              :key="plugin.id"
              cols="12"
              md="6"
              xl="4"
            >
              <c-plugin-card
                :ref="plugin.slug"
                :plugin="plugin"
                :period="periodType"
                :plan-slug="planSlug"
                :is-using="isPluginSelected(plugin)"
                @change="pluginOnChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
    <v-container
      v-if="!isInStore || (isInStore && selectedPluginsHasChanged)"
      fluid
      class="white pa-0 ma-0"
      :class="{
        'bottom-bar': !isInStore,
      }"
    >
      <v-row no-gutters>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col class="d-flex justify-center">
          <v-btn
            color="blue"
            dark
            class="ma-4"
            min-width="250"
            :loading="isProcessingPayment"
            :disabled="isProcessingPayment"
            x-large
            @click="payButtonClicked"
          >
            {{ $trans("go_to_summary") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CPluginCard from "@/views/dashboard/pages/Billing/components/CPluginCard.vue";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "PlanConfiguration",
  components: { CPluginCard },
  mixins: [planActions],
  props: {
    planSlug: {
      type: String,
      default: null,
    },
    planPeriodType: {
      type: String,
      default: null,
    },
    isInStore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPlugins: [],
      isProcessingPayment: false,
      initialSelectedPluginSlugs: null,
    };
  },
  computed: {
    selectedPluginsHasChanged() {
      return (
        this.initialSelectedPluginSlugs !==
        JSON.stringify(this.selectedPlugins.map((p) => p.id).sort())
      );
    },
    periodPaymentLabel() {
      if (this.periodType === this.$helpers.periodTypes.MONTH) {
        return this.$trans("monthly_no_vat");
      }
      if (this.periodType === this.$helpers.periodTypes.YEAR) {
        return this.$trans("yearly_no_vat");
      }

      return null;
    },
    selectedPlanPackages() {
      return this.selectedPlugins.filter(
        (item) => item.type === this.$helpers.planPackagesTypes.PLAN
      );
    },
    selectedExtraPackages() {
      return this.selectedPlugins.filter(
        (item) => item.type === this.$helpers.planPackagesTypes.EXTRA
      );
    },
    selectedPlanPackagesPrice() {
      let result = 0;
      this.selectedPlanPackages.forEach((item) => {
        if (item.type === this.$helpers.planPackagesTypes.PLAN) {
          result += item.price.amount;
        }
      });

      return result;
    },
    selectedExtraPackagesPrice() {
      let result = 0;
      this.selectedExtraPackages.forEach((item) => {
        if (item.type === this.$helpers.planPackagesTypes.EXTRA) {
          result += item.price.amount;
        }
      });

      return result;
    },
  },
  watch: {
    "getDialog.open": function () {
      if (this.getDialog.data && this.getDialog.data.subscribe === true) {
        this.processPayment();
      }
    },
  },
  created() {
    this.selectedPlanSlug = this.planSlug;
    this.selectedPeriodType = this.planPeriodType;

    this.selectPackages();

    this.$nextTick(() => {
      if (!this.isInStore) {
        this.$refs.mainContainer.scrollIntoView({ behavior: "smooth" });
      }

      this.selectedPlugins.forEach((item) => {
        if (this.$refs[item.slug]) {
          this.$refs[item.slug][0].selectPluginSilently();
        }
      });
    });
  },
  methods: {
    isPluginSelected(plugin) {
      return !!this.selectedPlugins.find((item) => item.slug === plugin.slug);
    },
    selectPackages() {
      const selectedTenantPackages = this.getSelectedTenantPackages();

      this.initialSelectedPluginSlugs = JSON.stringify(
        selectedTenantPackages.map((p) => p.id).sort()
      );

      // This case is when the user hits back button, we recheck them again
      if (this.subscriptionToPay) {
        this.subscriptionToPay.selectedPlanPackages.forEach((item) => {
          this.pluginOnChange({ plugin: item, selected: true });
        });

        this.subscriptionToPay.selectedExtraPackages.forEach((item) => {
          this.pluginOnChange({ plugin: item, selected: true });
        });
      } else {
        selectedTenantPackages.forEach((xPackage) => {
          this.pluginOnChange({ plugin: xPackage, selected: true });
        });
      }
    },
    getSelectedTenantPackages() {
      const result = [];
      this.tenantPlanPackages.forEach((tenantPackage) => {
        const xPackage = this.planPackages.find(
          (item) =>
            item.slug === tenantPackage.package.slug &&
            item.period === this.planPeriodType
        );

        if (xPackage && xPackage.price.amount > 0) {
          result.push(xPackage);
        }
      });

      return result;
    },
    pluginOnChange(data) {
      if (
        data.selected &&
        !this.selectedPlugins.find((plugin) => plugin.slug === data.plugin.slug)
      ) {
        if (data.plugin) {
          this.selectedPlugins.push(data.plugin);
        }
      } else if (!data.selected) {
        this.selectedPlugins = this.selectedPlugins.filter(
          (plugin) => plugin.slug !== data.plugin.slug
        );
      }
    },
    payButtonClicked() {
      this.setSubscriptionToPay({
        plan: this.plan,
        period: this.period,
        periodType: this.periodType,
        selectedPlanPackages: this.selectedPlanPackages,
        selectedExtraPackages: this.selectedExtraPackages,
        selectedPlanPackagesPrice: this.selectedPlanPackagesPrice,
        selectedExtraPackagesPrice: this.selectedExtraPackagesPrice,
        promoCode: null,
        promoCodePrice: 0,
      });

      this.$root.$emit("payForPlanEvent", {
        slug: this.plan.slug,
        period_type: this.periodType,
        plan_period_id: this.period.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
