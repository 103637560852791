<template>
  <v-row>
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">
          {{ $trans("event_history_title") }}:
        </div>
      </div>
      <div v-if="parsedEvent.createdBy">
        {{ $trans("created_by") }}: {{ parsedEvent.createdBy }} ({{
          parsedEvent.createdAt | dateTime($helpers.hourDayMonthAndYearDate)
        }})
      </div>
      <div v-if="parsedEvent.updatedBy">
        {{ $trans("updated_by") }}: {{ parsedEvent.updatedBy }} ({{
          parsedEvent.updatedAt | dateTime($helpers.hourDayMonthAndYearDate)
        }})
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EventHistory",
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
};
</script>
